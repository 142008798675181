<template>
  <v-snackbar v-model="showSnackbar" :timeout="timeout" :color="getColor()">
    {{ snackbarText }}
    <template v-slot:action="{ attrs }">
      <v-btn text icon v-bind="attrs" @click="closeSnackbar()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
// TODO: premestiti ovo u zaseban fajl
let errorCodeToText = {
  "company_undefined" : "Nije definisana kompanija čiji se šifarnik dodaje",
  "file_settings_undefined" : "Nisu definisana podešavanja fajla",
  "column_settings_undefined" : "Nisu definisana podešavanja kolona",
  "file_name_undefined" : "Nije definisan naziv fajla",
  "naziv_column_undefined" : "Nije definisana kolona u kojoj se nalazi naziv artikla",
  "sifra_column_undefined" : "Nije definisana kolona u kojoj se nalazi šifra artikla",
  "validation_failed" : "Greška u validaciji...",
  "file_undefined" : "Greška u Excel fajlu",
  "naziv_undefined" : "Fali naziv za neku od stavki",
  "sifra_undefined" : "Fali šifra za neku od stavki",
  "file_read_error" : "Greška prilikom čitanja fajla",
  "codebook_undefined" : "Nije definisan šifarnik",
  "sender_settings_undefined" : "Nisu definisana podešavanja pošiljaoca",
  "receiver_settings_undefined" : "Nisu definisana podešavanja primaoca",
};

export default {
  name: "Snackbar",
  data: function () {
    return {
      snackbarText: ""
    }
  },
  props: {
    timeout: {
      type: Number,
      default: -1,
      required: false
    },
    showSnackbar: {
      type: Boolean,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    isError: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    showSnackbar: {
      handler() {
        this.showError();
      },
      deep: true
    },
    text: {
      handler () {
        this.showError();
      },
      deep: true,
    }
  },
  methods: {
    getColor() {
      if (this.isError)
        return "error";
      else
        return "success";
    },
    showError() {
      if (!this.isError) {
          this.snackbarText = this.text;
          return;
        }

        // jeste error
        let errorText = errorCodeToText[this.text];
        if (errorText)
          this.snackbarText = errorText;
        else
          this.snackbarText = "Nepoznata greška... Kod: " + this.text;
    },
    closeSnackbar() {
      this.$emit('update:showSnackbar', false);
    }
  }
}
</script>
